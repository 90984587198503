import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/layout/main';
import ContactUs from '../components/widgets/contact-us';
import Head from '../components/layout/head';

const CountryBranchInfo = ({
  country,
  locationName,
  addressInfo,
  contact,
  email,
  mapLink,
}) => {
  return (
    <div className="row mb-5">
      <div className="col-12 col-sm-6 py-3 py-md-5">
        <div className="mw-230 mx-auto">
          <h3 className="fs-35 ganen-green-bold text-center mb-3 mb-md-5">
            {country}
          </h3>

          {locationName && <div className="mb-4">{locationName}</div>}

          <div className="mb-4">{addressInfo}</div>
          <div>
            <a
              href={`tel:${contact}`}
              className="text-decoration-none text-sky-blue"
            >
              {contact}
            </a>
          </div>
          <div>
            <a
              href={`mailto:${email}`}
              className="text-decoration-none text-sky-blue"
            >
              {email}
            </a>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6">
        <iframe
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen=""
          scrolling="no"
          title="Google Maps"
          aria-label="Google Maps"
          src={mapLink}
        ></iframe>
      </div>
    </div>
  );
};

const ContactUsPage = () => {
  const { site, map } = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          contactNumber
          email
        }
      }
      map: file(relativePath: { eq: "world-map.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Head title={'Contact Us'} />

      <div className="box-container my-5 px-3">
        <div className="fs-42 ganen-green-bold mb-3 mb-md-5 text-center">
          Contact Us
        </div>

        <CountryBranchInfo
          country="Philippines"
          addressInfo={
            <>
              <div>Static Power Philippines Inc</div>
              <div>#5 General Lim Street</div>
              <div>San Antonio Village</div>
              <div>Pasig City</div>
              <div>Philippines</div>
            </>
          }
          contact={`${site.siteMetadata.contactNumber}`}
          email={`${site.siteMetadata.email}`}
          mapLink="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.353406501449!2d121.06076031410703!3d14.578927181517685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c81344a6fc3d%3A0x38fdf7fe5c8c5f6e!2s5%20General%20Lim%2C%20San%20Antonio%2C%20Pasig%2C%201605%20Metro%20Manila!5e0!3m2!1sen!2sph!4v1601955139532!5m2!1sen!2sph"
        />

        <CountryBranchInfo
          country="Singapore"
          locationName="GANEN Pte Ltd"
          addressInfo={
            <>
              <div>10 Upper Aljunid Link, #05-04</div>
              <div>Singapore 367904</div>
            </>
          }
          contact="+65 6846 0081"
          email="info@ganen.asia"
          mapLink="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.737174249546!2d103.87985951515405!3d1.3338686619971738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da178fae80c877%3A0x65de654301f46913!2s10%20Upper%20Aljunied%20Link%2C%2005%2C%20Singapore%20367904!5e0!3m2!1sen!2sph!4v1601954308192!5m2!1sen!2sph"
        />

        <CountryBranchInfo
          country="Malaysia"
          locationName="GANEN IOT M SDN BHD"
          addressInfo={
            <>
              <div>Lot 3226, Jalan Perindustrian,</div>
              <div>Mahkota Utama</div>
              <div>Taman Perindustrian</div>
              <div>Mahkota Beranang,</div>
              <div>Selangor 43700 Malaysia</div>
            </>
          }
          contact="+603 8727 6080"
          email="purchasing@linkk.com.my"
          mapLink="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.748225955151!2d101.85611611404329!3d2.888784155505367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc34c933fa685d%3A0x4df1c454df891972!2sLinkk%20Busway%20Systems%20(M)%20Sdn.%20Bhd.!5e0!3m2!1sen!2sph!4v1601955394835!5m2!1sen!2sph"
        />

        <div className="mb-5">
          <BackgroundImage
            fluid={map.childImageSharp.fluid}
            className="mx-auto worl-map"
          ></BackgroundImage>
        </div>

        <ContactUs sectionClass="bg-ganen-gray-3" />
      </div>
    </Layout>
  );
};

export default ContactUsPage;
